import React from "react";
import moment from "moment";

const email = "contact@davidkaufman.de";

export const Main: React.FC = () => (
  <div id="page-top">
    <nav
      className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
      id="sideNav"
    >
      <a className="navbar-brand" href={"#page-top"}>
        <span className="d-block d-lg-none">David Kaufman</span>
        <span className="d-none d-lg-block">
          <img
            className="img-fluid img-profile rounded-circle mx-auto mb-2"
            src={"img/profile.jpg"}
            alt="profile of me"
          />
        </span>
      </a>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" href={"#about"}>
              About
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={"#experience"}>
              Experience
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={"#skills"}>
              Skills
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={"#education"}>
              Education
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={"#awards"}>
              Awards
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href={"#contact"}>
              Get In Touch
            </a>
          </li>
        </ul>
      </div>
    </nav>

    <div className="container-fluid p-0">
      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="about"
      >
        <div className="w-100">
          <h1 className="mb-0">
            David
            <span className="text-primary">Kaufman</span>
          </h1>
          <div className="subheading mb-5">
            <img
              className={"flag"}
              alt={"icon of hamburg"}
              src={"img/hamburg.png"}
            />{" "}
            Hamburg ·{" "}
            <img
              className={"flag"}
              alt={"icon of the flag of germany"}
              src={"img/german-flag.png"}
            />{" "}
            Germany · <a href={`mailto:${email}`}>{email}</a>
          </div>
          <p className="lead mb-5">
            I'm a senior full-stack software engineer based in Hamburg, Germany
            currently specializing in building highly available, scalable cloud
            native applications. I have a demonstrated history of working in
            various industries including automobile, real-estate and e-commerce.
          </p>
          <div className="social-icons">
            <a href="https://www.linkedin.com/in/david-kaufman-133b52a1/">
              <i className="fab fa-linkedin-in" />
            </a>
            <a href="https://www.xing.com/profile/David_Kaufman3/cv">
              <i className="fab fa-xing" />
            </a>
            <a href="https://github.com/anopheles">
              <i className="fab fa-github" />
            </a>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        id="experience"
      >
        <div className="w-100">
          <h2 className="mb-5">Experience</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Software-Engineer</h3>
              <div className="subheading mb-3">
                <img
                  className="company"
                  src={"img/freiheit.jpg"}
                  alt={"logo of freiheit.com"}
                />
                freiheit.com technologies gmbh, Germany
              </div>
              <p>
                Full-Stack Engineer working with Go, React, Kubernetes, Google
                Cloud Platform.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">
                October 2014 - Present
                <br />
                {moment("20141001", "YYYYMMDD").toNow(true)}
              </span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">Research Assistant</h3>
              <div className="subheading mb-3">
                <img
                  className="company"
                  src={"img/astar.png"}
                  alt={"logo of astar"}
                />
                A*STAR - Agency for Science, Technology and Research, Singapore
              </div>
              <p>Master Thesis Internship</p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">
                December 2013 - April 2014
                <br />
                {moment("20131201", "YYYYMMDD").to(
                  moment("20140401", "YYYYMMDD"),
                  true
                )}
              </span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">Software Engineer & Research Assistant</h3>
              <div className="subheading mb-3">
                <img
                  className="company"
                  src={"img/kit.png"}
                  alt={"logo of kit"}
                />
                Karlsruher Institut für Technologie (KIT), Germany
              </div>
              <p>
                Developed internal tools for data analysis of biosignals in
                Python and Java.
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">
                2011 - 2013
                <br />
                {moment("20110501", "YYYYMMDD").to(
                  moment("20131101", "YYYYMMDD"),
                  true
                )}
              </span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="skills"
      >
        <div className="w-100">
          <h2 className="mb-3">Skills</h2>
          Strong knowledge of golang, python and the Google Cloud Platform.
          Solid knowledge of Kubernetes and ReactJS. Experienced with Linux
          development and distributed version control.
          <h3 className="mt-2 mb-2">Technologies & Tools</h3>
          <div className="subheading mb-1">Backend</div>
          <ul className="fa-ul mb-3">
            <li>
              <i className="fa-li fa fa-check" />
              Golang
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Python
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Protobuf/gRPC
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Java 8+
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Docker, Kubernetes
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              MySQL, PostgresSQL
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Jenkins, Gitlab, Heroku, Netlify
            </li>
          </ul>
          <div className="subheading mb-1">Frontend</div>
          <ul className="fa-ul mb-3">
            <li>
              <i className="fa-li fa fa-check" />
              ReactJS, Typescript
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Progressive Web Apps (PWA)
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Material Components, Bootstrap
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              HTML5 + CSS/SCSS/SASS
            </li>
          </ul>
          <div className="subheading mb-1">
            Big Data / Data Science / Machine Learning
          </div>
          <ul className="fa-ul mb-3">
            <li>
              <i className="fa-li fa fa-check" />
              Numpy, Pandas, scikit-learn
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Big Query
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              Elasticsearch / ELK-Stack
            </li>
            <li>
              <i className="fa-li fa fa-check" />
              fluentd
            </li>
          </ul>
          <div className={"mb-5"}>
            <h3>Github Coding Activity</h3>
            <div className={"row"}>
              <a href="https://github.com/anopheles">
                <img
                  style={{ maxWidth: "700px" }}
                  className={"col"}
                  src="https://ghchart.rshah.org/anopheles"
                  alt="David's Github chart"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="education"
      >
        <div className="w-100">
          <h2 className="mb-5">Education</h2>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div className="resume-content">
              <h3 className="mb-0">
                <img
                  className="company"
                  src={"img/kit.png"}
                  alt={"logo of kit"}
                />
                Master of Science
              </h3>
              <div className="subheading mb-3">
                Computer Science, Karlsruhe Institute of Technology
              </div>
              <p>
                Master Thesis: "Spatiotemporal Localization of Visual Events
                using EEG and Eye Tracker" with publication in NordiCHI 2014,
                Prof. Dr.-Ing. Tanja Schultz
              </p>
              <p>GPA: 1,3 (1: very good, 6: insufficient)</p>
              <p>
                Major: Artificial Intelligence, Software-Development <br />{" "}
                Minor: Genetics
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2012 - 2014</span>
            </div>
          </div>

          <div className="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div className="resume-content">
              <h3 className="mb-0">
                <img
                  className="company"
                  src={"img/kit.png"}
                  alt={"logo of kit"}
                />
                Bachelor of Science
              </h3>
              <div className="subheading mb-3">
                Computer Science, Karlsruhe Institute of Technology
              </div>
              <p>
                Bachelor Thesis: "Interactive Procedural Modeling and Animation
                of Implicit Surface", Prof. Dr.-Ing. Carsten Dachsbacher
              </p>
              <p>
                Major: Computer Graphics <br />
                Minor: Physics
              </p>
            </div>
            <div className="resume-date text-md-right">
              <span className="text-primary">2008 - 2012</span>
            </div>
          </div>
        </div>
      </section>

      <hr className="m-0" />

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="awards"
      >
        <div className="w-100">
          <h2 className="mb-5">Awards &amp; Certifications</h2>
          <ul className="nobull mb-0">
            <li className={"mb-2"}>
              <img
                className="company"
                src={"img/scrumalliance.png"}
                alt={"logo of kit"}
              />
              Certified Scrum Product Owner® (CSPO®), 2017
            </li>
            <li className={"mb-2"}>
              <img
                className="company"
                src={"img/kit.png"}
                alt={"logo of kit"}
              />
              Member of the "Begabtenkolleg der Fakultät für Informatik" at the
              KIT, 2014
            </li>
          </ul>
        </div>
      </section>

      <section
        className="resume-section p-3 p-lg-5 d-flex align-items-center"
        id="contact"
      >
        <div className="w-100">
          <h2 className="mb-3">Get In Touch</h2>
          Although I'm not currently looking for freelance opportunities, my
          inbox is always open. Whether for a potential project or just to say
          hi, I'll try my best to answer your email!
          <div className={"my-2"}>
            <a className="btn btn-primary" href={`mailto:${email}`}>
              <i className="far fa-envelope mx-1" />
              Contact
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
);
