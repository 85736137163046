import React, { useLayoutEffect } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./scss/App.scss";

import { Main } from "./Main";

declare var $: any;

const App: React.FC = () => {
  useLayoutEffect(() => {
    $(".nav-link").on("click", function() {
      $(".navbar-collapse").collapse("hide");
    });

    // Activate scrollspy to add active class to navbar items on scroll
    $("body").scrollspy({
      target: "#sideNav"
    });
  });

  return (
    <Router>
      <Switch>
        <Route path="/imprint">
          <div> IMPRINT </div>
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
